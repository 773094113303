import React, { useEffect, useState } from "react";
import { ElementWithMaterialDetails, MaterialType } from "@twinsketch/topika-model";
import { FormulationToMixingHelper } from './utils/helper';
import 'src/styles/workOrder/formulation-table.css';

const DynamicTable: React.FC<{
    materialType: string;
    ratio: number;
    elements: ElementWithMaterialDetails[];
}> = ({ materialType, ratio, elements }) => (
    <tr>
        <th>{materialType}</th>
        <td>
            <span>{ratio}%</span>
        </td>
        <td>
            {elements?.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            {elements.map((product, index) => (
                                <th key={`header-${materialType}-${index}`}>{product.material.modelName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {elements.map((product, index) => (
                                <td key={`product-${materialType}-${index}`}>
                                    <span>
                                        {((product.ratio / ratio) * 100).toFixed(2)}%
                                    </span>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            )}
        </td>
    </tr>
);

const groupElementsByMaterialType = (
    elements: ElementWithMaterialDetails[] | null
): Record<MaterialType, ElementWithMaterialDetails[]> => {
    if (!elements) return {} as Record<MaterialType, ElementWithMaterialDetails[]>;

    return elements.reduce((acc, element) => {
        const type = element.material.materialType;
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(element);
        return acc;
    }, {} as Record<MaterialType, ElementWithMaterialDetails[]>);
};

interface FormulationTableProps {
    formulation: FormulationToMixingHelper | null;
    setFormulation: React.Dispatch<React.SetStateAction<FormulationToMixingHelper | null>>;
    onFormulationUpdate: (updatedFormulation: FormulationToMixingHelper) => void;
}

const FormulationTable: React.FC<FormulationTableProps> = ({
    formulation,
    setFormulation,
    onFormulationUpdate,
}) => {
    const [localFormulation, setLocalFormulation] = useState<FormulationToMixingHelper | null>(formulation);

    useEffect(() => {
        setLocalFormulation(formulation);
    }, [formulation]);

    if (!localFormulation) {
        return <div>Loading...</div>;
    }

    const groupedElements = groupElementsByMaterialType(localFormulation.formulation?.elements || []);

    return (
        <div>
            <h4>{localFormulation?.formulation ? localFormulation.formulation.name : "No Formulation Selected"}</h4>
            <table className="formulation-table">
                <thead>
                    <tr>
                        <th>Material Type</th>
                        <th>Ratio (%)</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {localFormulation && Object.keys(localFormulation.ratiosByMaterialType).length > 0 ? (
                        Object.entries(localFormulation.ratiosByMaterialType)
                            .filter(([_, ratio]) => ratio > 0)
                            .map(([materialType, ratio], index) => (
                                <DynamicTable
                                    key={`ratio-${index}`}
                                    materialType={materialType}
                                    ratio={ratio}
                                    elements={groupedElements[materialType as MaterialType] || []}
                                />
                            ))
                    ) : (
                        <tr>
                            <td colSpan={3} style={{ textAlign: 'center' }}>
                                No formulation data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default FormulationTable;