import React from "react";

// Define the allowed cell types
export type CellType = "editable" | "calculated" | "disabled";

// Define the allowed field types
export type FieldType = "number" | "text";

// Define the structure for each field's configuration
export interface FieldConfigItem {
    cellType: CellType;
    type?: FieldType; // Optional because 'disabled' might not require it
}

// Define props for the RenderCell component
export interface RenderCellProps {
    value: any;
    path: (string | number)[];
    fieldType?: FieldType;
    cellType: CellType;
    handleChange: (value: any, path: string[]) => void;
}

const RenderCell: React.FC<RenderCellProps> = ({ value, path, fieldType = "text", cellType, handleChange }) => {
    const stringPath = path.map(segment => segment.toString());

    switch (cellType) {
        case "editable":
            return (
                <td className="editable-cell">
                    <input
                        type={fieldType}
                        value={value !== null && value !== undefined ? String(value) : ""}
                        onChange={(e) =>
                            handleChange(
                                fieldType === "number"
                                    ? e.target.value
                                        ? parseFloat(e.target.value)
                                        : null
                                    : e.target.value,
                                stringPath
                            )
                        }
                        style={{ width: "100%" }}
                    />
                </td>
            );
        case "calculated":
            return (
                <td className="calculated-cell">
                    {value !== null && value !== undefined ? value : ""}
                </td>
            );
        case "disabled":
            return (
                <td className="disabled-cell">
                    <span>—</span> {/* Placeholder or leave empty based on your requirement */}
                </td>
            );
        default:
            return <td></td>;
    }
};

export default RenderCell;
