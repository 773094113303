import { ElectrodeType, MaterialType } from '@twinsketch/topika-model';

export interface MaterialFieldConfig {
  name: string;
  type: 'text' | 'number' | 'select';
  required?: boolean;
  column?: boolean;
  electrodeType?: string;
}

export const materialFieldsByType: Record<MaterialType, MaterialFieldConfig[]> = {
  [MaterialType.ACTIVE_MATERIAL]: [
    { name: 'activeMaterialCategory', type: 'select', required: true, column: false, electrodeType: 'common' },
    { name: 'materialName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'modelName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'supplier', type: 'text', required: true, column: true, electrodeType: 'common' },

    { name: 'dischargeCapacity', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'initialEfficiency', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'sizeD50', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'sizeDmax', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'bet', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'tapDensity', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'moisture', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'locationTag', type: 'select', required: false, column: true, electrodeType: 'common' },
  ],
  [MaterialType.CONDUCTING_AGENT]: [
    { name: 'materialForm', type: 'select', required: true, column: true, electrodeType: 'common' },
    { name: 'materialName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'modelName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'supplier', type: 'text', required: true, column: true, electrodeType: 'common' },

    { name: 'bet', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'feImpurity', type: 'number', required: true, column: true, electrodeType: 'common' },

    { name: 'totalSolidContent', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'cntContent', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'viscosity', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'moisture', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'locationTag', type: 'select', required: false, column: true, electrodeType: 'common' },
  ],
  [MaterialType.BINDER]: [
    { name: 'materialForm', type: 'select', required: true, column: true, electrodeType: 'common' },
    { name: 'materialName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'modelName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'supplier', type: 'text', required: true, column: true, electrodeType: 'common' },

    { name: 'meltingPoint', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'volatilesContent', type: 'number', required: true, column: true, electrodeType: 'Cathode' },

    { name: 'totalSolidContent', type: 'number', required: true, column: true, electrodeType: 'Anode' },
    { name: 'viscosity', type: 'number', required: true, column: true, electrodeType: 'Anode' },
    { name: 'ph', type: 'number', required: true, column: true, electrodeType: 'Anode' },

    { name: 'moisture', type: 'number', required: true, column: true, electrodeType: 'Anode' },
    { name: 'purity', type: 'number', required: true, column: true, electrodeType: 'Anode' },
    { name: 'locationTag', type: 'select', required: false, column: true, electrodeType: 'common' },
  ],
  [MaterialType.ADDITIVE]: [
    { name: 'materialForm', type: 'select', required: true, column: true, electrodeType: 'common' },
    { name: 'materialName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'modelName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'supplier', type: 'text', required: true, column: true, electrodeType: 'common' },

    { name: 'meltingPoint', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'volatilesContent', type: 'number', required: true, column: true, electrodeType: 'Cathode' },

    { name: 'totalSolidContent', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'moisture', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'locationTag', type: 'text', required: false, column: true, electrodeType: 'common' },
  ],
  [MaterialType.SOLVENT]: [
    { name: 'materialName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'modelName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'supplier', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'purity', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'moisture', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'ph', type: 'number', required: true, column: true, electrodeType: 'Cathode' },
    { name: 'conductivity', type: 'number', required: true, column: true, electrodeType: 'Anode' },
    { name: 'boilingPoint', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'locationTag', type: 'select', required: false, column: true, electrodeType: 'common' },
  ],
  [MaterialType.CURRENT_COLLECTOR]: [
    { name: 'materialName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'modelName', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'supplier', type: 'text', required: true, column: true, electrodeType: 'common' },
    { name: 'thickness', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'tensileStrength', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'elongation', type: 'number', required: true, column: true, electrodeType: 'common' },
    { name: 'locationTag', type: 'select', required: false, column: true, electrodeType: 'common' },
  ],
  [MaterialType.UNKNOWN]: [],
  [MaterialType.UNSPECIFIED]: [],
};

export const getFieldsForMaterialType = (
  materialType: MaterialType,
  electrode: ElectrodeType
): MaterialFieldConfig[] => {
  return materialFieldsByType[materialType]
    .filter(field => field.electrodeType === 'common' || field.electrodeType === electrode)
    .map(field => ({
      ...field,
      required: true, // Ensure all fields are required
      column: true,   // Ensure all fields are part of a column
    }));
};
