import FileService from 'src/services/FileService';
import { UploadedFile } from '@twinsketch/topika-model';

/**
 * Handles file uploads and deletions.
 * You can pass entityId for operations with an ID (e.g., for updates).
 */
export const FileHandler = {
  async uploadFilesWithoutId(pendingFiles: any[]): Promise<UploadedFile[]> {
    const uploadedFiles: UploadedFile[] = [];
    for (const pendingFile of pendingFiles) {
      try {
        const uploadedFile = await FileService.uploadFileWithoutId(pendingFile.file, pendingFile.notes);
        uploadedFiles.push(uploadedFile);
      } catch (error) {
        console.error('File upload failed:', error);
      }
    }
    return uploadedFiles;
  },

  async uploadFilesWithId(pendingFiles: any[], entityId: string): Promise<UploadedFile[]> {
    const uploadedFiles: UploadedFile[] = [];
    for (const pendingFile of pendingFiles) {
      try {
        const uploadedFile = await FileService.uploadFileWithId(pendingFile.file, entityId, pendingFile.notes,);
        uploadedFiles.push(uploadedFile);
      } catch (error) {
        console.error('File upload failed:', error);
      }
    }
    return uploadedFiles;
  },

  async deleteFiles(deletedFilesList: UploadedFile[], entityId: string) {
    for (const file of deletedFilesList) {
      try {
        await FileService.deleteFile(file.id, entityId);
      } catch (error) {
        console.error('File delete failed:', error);
      }
    }
  },

  async moveFiles(uploadedFiles: UploadedFile[], entityId: string) {
    for (const file of uploadedFiles) {
      try {
        await FileService.moveFile(file.id, entityId, file.fileName, file.notes || '');
      } catch (error) {
        console.error('File move failed:', error);
      }
    }
  }
};
