import { MixingFormulationWithMaterials, MaterialType, ElementWithMaterialDetails } from '@twinsketch/topika-model';
import { BinderSolutionData } from './MixingTableData';

export interface FormulationToMixingHelper {
    ratiosByMaterialType: Record<MaterialType, number>;
    activeMaterialKg: number;
    formulation: MixingFormulationWithMaterials;
    elementsByMaterialType: Record<MaterialType, ElementWithMaterialDetails[]>;
}

/**
 * Translate a MixingFormulationWithMaterials to a FromulationToMixingHelper.
 * @param formulation The input MixingFormulationWithMaterials object.
 * @param activeMaterialKg The active material weight in kg.
 * @returns The translated FromulationToMixingHelper object.
 */
const translateToFromulationToMixingHelper = (
    formulation: MixingFormulationWithMaterials,
    activeMaterialKg: number
): FormulationToMixingHelper => {
    const ratiosByMaterialType: Record<MaterialType, number> = {
        [MaterialType.ACTIVE_MATERIAL]: 0,
        [MaterialType.CONDUCTING_AGENT]: 0,
        [MaterialType.BINDER]: 0,
        [MaterialType.ADDITIVE]: 0,
        [MaterialType.UNSPECIFIED]: 0,
        [MaterialType.SOLVENT]: 0,
        [MaterialType.CURRENT_COLLECTOR]: 0,
        [MaterialType.UNKNOWN]: 0,
    };

    const elementsByMaterialType: Record<MaterialType, ElementWithMaterialDetails[]> = {
        [MaterialType.ACTIVE_MATERIAL]: [],
        [MaterialType.CONDUCTING_AGENT]: [],
        [MaterialType.BINDER]: [],
        [MaterialType.ADDITIVE]: [],
        [MaterialType.UNSPECIFIED]: [],
        [MaterialType.SOLVENT]: [],
        [MaterialType.CURRENT_COLLECTOR]: [],
        [MaterialType.UNKNOWN]: [],
    }

    formulation.elements.forEach((element) => {
        const materialType = element.material.materialType;
        if (materialType in ratiosByMaterialType) {
            ratiosByMaterialType[materialType] += element.ratio;
            elementsByMaterialType[materialType].push(element);
        }
    });

    return {
        formulation,
        ratiosByMaterialType,
        activeMaterialKg,
        elementsByMaterialType
    };
};

export const defaultRatiosByMaterialType: Record<MaterialType, number> = {
    [MaterialType.ACTIVE_MATERIAL]: 0,
    [MaterialType.CONDUCTING_AGENT]: 0,
    [MaterialType.BINDER]: 0,
    [MaterialType.ADDITIVE]: 0,
    [MaterialType.UNSPECIFIED]: 0,
    [MaterialType.SOLVENT]: 0,
    [MaterialType.CURRENT_COLLECTOR]: 0,
    [MaterialType.UNKNOWN]: 0,
};
function percentageStringToNumber(percentage: string): number {
    // Remove the '%' sign and parse it to a float
    const numericValue = parseFloat(percentage.replace('%', ''));
    // Convert to a decimal number
    return numericValue / 100;
}

// export const defaultNewBinderMixingData: BinderSolutionData = {
//     // process: "Make binder solution (700L PDM)",
//     materials: [
//         {
//             name: "PVdf S5140",
//             rowSpan: 2,
//             subMaterials: [
//                 { name: "NMP", displayingName: "NMP", weight: 0, density: 1.03, volume: 0, leadTime: "", remark: "" },
//                 { name: "PVdF", displayingName: "PVdF", weight: 0, density: 1.77, volume: 0, leadTime: "", remark: "" },
//             ],
//         },
//     ],

//     mixing: { name: "Mixing", leadTime: "180", remark: "" },
//     solidContent: { name: "% Solid Content", solidContent: 6, remark: "", },
//     total: {
//         name: "Total",
//         colSpan: 3,
//         weight: 270.83,
//         density: 1.06,
//         volume: 256.35,
//         leadTime: "",
//         remark: "",
//     },
// };

export const emptyBinder: BinderSolutionData = {
    // process: "Make binder solution (700L PDM)",
    materials: [
        {
            name: "{BinderModelName}", //binder model name (find materialtype == binder )
            rowSpan: 2,
            subMaterials: [
                { name: "NMP", weight: 0, density: 1.03, volume: 0, leadTime: "", remark: "" },
                { name: "PVdF", displayingName: "PVdF", weight: 0, density: 1.77, volume: 0, leadTime: "", remark: "" },
            ],
        },
    ],
    mixing: { name: "Mixing", leadTime: "180", remark: "" },
    solidContent: { name: "% Solid Content", solidContent: 6, remark: "", },
    total: {
        name: "Total",
        colSpan: 3,
        weight: 0,
        density: 0,
        volume: 0,
        leadTime: "",
        remark: "",
    },
};

export { translateToFromulationToMixingHelper, percentageStringToNumber };
