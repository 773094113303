import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { UploadedFile } from '@twinsketch/topika-model';
import './../../styles/file-list.css'

interface PendingFile {
  file: File;
  notes: string;
}

interface FileUploadProps {
  onFilesChange: (pendingFiles: PendingFile[], existingFiles: UploadedFile[]) => void;
  existingFiles?: UploadedFile[];
  onDeleteExistingFile?: (fileId: string) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFilesChange, existingFiles = [], onDeleteExistingFile }) => {
  const [pendingFiles, setPendingFiles] = useState<PendingFile[]>([]);
  const [currentFiles, setCurrentFiles] = useState<UploadedFile[]>(existingFiles);
  const [filePreview, setFilePreview] = useState<null | string | React.ReactNode>(null);

  useEffect(() => {
    if (JSON.stringify(currentFiles) !== JSON.stringify(existingFiles)) {
      setCurrentFiles(existingFiles);
    }
  }, [existingFiles, currentFiles]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map(file => ({ file, notes: '' }));
      const updatedFiles = [...pendingFiles, ...filesArray];
      setPendingFiles(updatedFiles);
      onFilesChange(updatedFiles, currentFiles);
    }
  };

  const handleFileNoteChange = (index: number, notes: string) => {
    const updatedFiles = pendingFiles.map((file, i) => (i === index ? { ...file, notes } : file));
    setPendingFiles(updatedFiles);
    onFilesChange(updatedFiles, currentFiles);
  };

  const handleExistingFileNoteChange = (fileId: string, notes: string) => {
    const updatedFiles = currentFiles.map(file => (file.id === fileId ? { ...file, notes } : file));
    setCurrentFiles(updatedFiles);
    onFilesChange(pendingFiles, updatedFiles);
  };

  const handleFileDelete = (index: number) => {
    const updatedFiles = pendingFiles.filter((_, i) => i !== index);
    setPendingFiles(updatedFiles);
    onFilesChange(updatedFiles, currentFiles);
  };

  const handleDeleteExistingFile = (fileId: string) => {
    if (onDeleteExistingFile) {
      onDeleteExistingFile(fileId);
      const updatedExistingFiles = currentFiles.filter(file => file.id !== fileId);
      setCurrentFiles(updatedExistingFiles);
      onFilesChange(pendingFiles, updatedExistingFiles);

      // If there are no files left after deletion, clear the preview
      if (updatedExistingFiles.length === 0 && pendingFiles.length === 0) {
        setFilePreview(null); // Clear the preview
      }
    }
  };

  return (
    <div className="file-upload-section">
      <input type="file" onChange={handleFileChange} multiple />

      {/* Existing Files */}
      {currentFiles.length > 0 && (
        <div className="file-list">
          <label className="field-label">Existing Files</label>
          {currentFiles.map((file) => (
            <div key={file.id} className="file-item">
              <div className="file-name">
                <span>{file.fileName}</span>
              </div>
              <div className="file-notes-container">
                <input
                  type="text"
                  placeholder="Modify notes"
                  value={file.notes || ''}
                  onChange={(e) => handleExistingFileNoteChange(file.id, e.target.value)}
                  className="file-notes-edit"
                />
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleDeleteExistingFile(file.id)}
                  className="file-delete-icon"
                />
              </div>

            </div>
          ))}
        </div>
      )}

      {/* New Pending Files */}
      {pendingFiles.length > 0 && (
        <div className="file-list">
          <label className="field-label">New Files</label>
          {pendingFiles.map((file, index) => (
            <div key={index} className="file-item">
              <div className="file-name">
                <span>{file.file.name}</span>
              </div>
              <div className="file-notes-container">
                <input
                  type="text"
                  placeholder="Add notes for the file"
                  value={file.notes}
                  onChange={(e) => handleFileNoteChange(index, e.target.value)}
                  className="file-notes-edit"
                />
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleFileDelete(index)}
                  className="file-delete-icon"
                />
              </div>

            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { FileUpload };
export type { PendingFile };

