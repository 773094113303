import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from 'src/components/common/Header';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import ErrorModal from 'src/components/common/ErrorModal';
import { WorkOrder } from '@twinsketch/topika-model';
import ApiService from 'src/services/ApiService';
import ListHeader from 'src/components/listPage/ListHeader';
import { FaFile } from 'react-icons/fa';
import FileService from 'src/services/FileService';

const FileIndicator = ({ workOrderId, workOrderName, category, files, onDownload }) => {
    const hasFile = Array.isArray(files) && files.some(file => file.category === category);

    return hasFile ? (
        <FaFile
            title="File available"
            style={{ cursor: 'pointer' }}
            onClick={() => onDownload(workOrderId, workOrderName, category)}
        />
    ) : null;
};

const WorkOrderList: React.FC = () => {
    // const { workOrders, setWorkOrders } = useAppData();

    const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
    const [filteredWorkOrders, setFilteredWorkOrders] = useState<WorkOrder[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();

    // Custom hook or direct data fetching
    useEffect(() => {
        const fetchWorkOrders = async () => {
            try {
                const response = await ApiService.fetchWorkOrders(); // Replace with the actual API call
                setWorkOrders(response);
            } catch (err) {
                setError('Error fetching work orders.');
                console.error(err);
            }
        };

        fetchWorkOrders();
    }, [setWorkOrders]);

    useEffect(() => {
        setFilteredWorkOrders(workOrders);
    }, [workOrders]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = workOrders.filter((workOrder) => {
            return
                // workOrder.name.toLowerCase().includes(term) ||
                // (workOrder.description && workOrder.description.toLowerCase().includes(term))
                ;
        });

        setFilteredWorkOrders(filtered);
    };

    const handleFileDownload = async (workOrderId, workOrderName, category) => {
        try {
            // Fetch the list of files for the work order
            const files = await FileService.getFiles(workOrderId);
            const file = files.find(f => f.category === category);

            if (file && file.s3Url) {
                // Fetch the file data as a Blob
                const response = await fetch(file.s3Url);
                const blob = await response.blob();

                // Determine a file extension from the URL or blob type if needed
                const urlParts = file.s3Url.split('.');

                // Create a temporary anchor element to trigger download
                const link = document.createElement('a');
                const blobUrl = window.URL.createObjectURL(blob);
                link.href = blobUrl;
                // Set the custom filename combining work order name and category
                link.download = `${workOrderName}-${category}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            } else {
                alert('File not found');
            }
        } catch (error) {
            console.error('Error fetching or downloading file:', error);
            alert('An error occurred while fetching the file.');
        }
    };

    const fileCategories = [
        { key: 'WORK_ORDER', label: t('작업지시서') },
        { key: 'MIXING_REPORT', label: t('믹싱일보') },
        { key: 'COATING_REPORT', label: t('코팅일보') },
        { key: 'PRESS_REPORT', label: t('프레스') },
        { key: 'SLITTING_REPORT', label: t('슬리팅') }
    ];

    return (
        <div>
            <Header />
            <ErrorModal message={error} onClose={() => setError(null)} />
            <main className="list-main-content">
                <ListHeader
                    createRoute={ROUTES.WORK_ORDERS_CREATE}
                    addButtonLabel="등록"
                    searchPlaceholder="작업 지시 검색"
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                />
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('workOrderDataField.name')}</th>
                                <th>{t('workOrderDataField.formulationName')}</th>
                                <th>{t('workOrderDataField.activeMaterialKg')}</th>
                                <th>{t('workOrderDataField.mixingTankVolumeFull')}</th>
                                <th>{t('작업지시서')}</th>
                                <th>{t('믹싱일보')}</th>
                                <th>{t('코팅일보')}</th>
                                <th>{t('프레스')}</th>
                                <th>{t('슬리팅')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredWorkOrders.map((workOrder) => (
                                <tr key={workOrder.id}>
                                    <td>
                                        <Link to={ROUTES.WORK_ORDERS_DETAIL(workOrder.id)}>{workOrder.name || '-'}</Link>
                                    </td>
                                    <td>{workOrder.formulationName || '-'}</td>
                                    <td>{workOrder.activeMaterialKg || '-'}</td>
                                    <td>{workOrder.mixingTankVolumeFull || '-'}</td>
                                    {fileCategories.map(({ key }) => (
                                        <td key={key}>
                                            <FileIndicator
                                                workOrderId={workOrder.id}
                                                workOrderName={workOrder.name || 'Unknown'}
                                                category={key}
                                                files={workOrder.files}
                                                onDownload={handleFileDownload}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </main>
        </div>
    );
};

export default WorkOrderList;
