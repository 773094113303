const ROUTES = {
  LOGIN: '/login',
  MAIN: '/main',

  USER_PROFILE: '/user-profile',

  WORK_ORDERS: '/work-orders',
  WORK_ORDERS_CREATE: '/work-orders/create',
  // WORK_ORDERS_DETAIL: (id: string, tab: string = '') => `/work-orders/${id}${tab ? `?tab=${tab}` : ''}`,
  WORK_ORDERS_DETAIL: (id: string, tab: string = '') => `/work-orders/${id}`,
  WORK_ORDERS_EDIT: (id = ':id') => `/work-orders/edit/${id}`,

  MATERIALS: '/materials',
  MATERIALS_CREATE: '/materials/create',
  MATERIALS_DETAIL: (id: string) => `/materials/${id}`,
  MATERIALS_EDIT: (id: string) => `/materials/edit/${id}`,

  FORMULATIONS: '/formulations',
  FORMULATIONS_CREATE: '/formulations/create',
  FORMULATIONS_DETAIL: (id: string) => `/formulations/${id}`,
  FORMULATIONS_EDIT: (id: string) => `/formulations/edit/${id}`,
};

export const getDetailRoute = (type: 'WORK_ORDERS' | 'MATERIALS' | 'FORMULATIONS' , id: string) => {
  switch (type) {
    case 'MATERIALS':
      return ROUTES.MATERIALS_DETAIL(id);
    case 'FORMULATIONS':
      return ROUTES.FORMULATIONS_DETAIL(id);
    case 'WORK_ORDERS':
      return ROUTES.WORK_ORDERS_DETAIL(id);
    default:
      throw new Error('Invalid route type');
  }
};

export default ROUTES;