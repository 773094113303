import { getIdToken, User } from "firebase/auth";
import { auth } from "src/config/firebase";

export const isTokenValid = (allowRefresh = true): boolean => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    return false;
  }

  const currentTime = Date.now() / 1000;
  const { iat, exp } = parseJwt(token);

  const refreshWindow = parseInt(process.env.REACT_APP_TOKEN_REFRESH_WINDOW_HOURS) * 3600;
  if (currentTime < exp) return true;

  if (allowRefresh && exp <= currentTime && currentTime < refreshWindow + iat) {
    // if token expired and issue time is within refresh window
    refreshToken();
    return isTokenValid(false);
  }
  return false;
};

const refreshToken = async () => {
  const user = auth.currentUser;
  if (user === null) return;

  const idToken = await getIdToken(user, true);
  // Set the token in localStorage
  localStorage.setItem('authToken', idToken);
}

function parseJwt(token: string) {
  return JSON.parse(atob(token.split('.')[1]));
}