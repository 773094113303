import React, { useState } from 'react';
import { Category, UploadedFile } from '@twinsketch/topika-model';
import FileService from 'src/services/FileService';

const ExcelFileUploader = ({ onFileUpload, workOrderId }: { onFileUpload: (uploadedFiles: UploadedFile) => void, workOrderId: string }) => {
    const [file, setFile] = useState<File | null>(null);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [notes, setNotes] = useState<string>('');
    const [fileCategory, setFileCategory] = useState<Category | null>(null);
    const [uploadComplete, setUploadComplete] = useState(false);
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files ? e.target.files[0] : null;
        if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(selectedFile);
            setIsFileSelected(true);
            setUploadComplete(false);
        } else {
            alert('Please select a valid Excel file.');
        }
    };

    const handleCategoryChange = (selectedCategory: Category) => {
        setFileCategory(selectedCategory);
    };

    const handleUpload = async () => {
        if (!file || !fileCategory) {
            alert('Please select a file and category.');
            return;
        }
        try {
            const uploadedFile = await FileService.uploadFileWithId(file, workOrderId, notes, fileCategory);
            onFileUpload(uploadedFile);
            setUploadComplete(true); // Set flag on successful upload

            setFile(null);
            setIsFileSelected(false);
            setNotes('');
            setFileCategory(null);

        } catch (err) {
            console.error('Error uploading file:', err);
            alert('Failed to upload the file.');
        }
    };

    return (
        <div>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            {file && <p>File Selected: {file.name}</p>}

            {file && (
                <div>
                    <label htmlFor="notes">Notes: </label>
                    <input
                        type="text"
                        id="notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Enter notes for this file"
                    />
                </div>
            )}

            {isFileSelected && (
                <div>
                    <p>Select a Category:</p>
                    {Object.values(Category).map((cat) => (
                        <div key={cat}>
                            <input
                                type="radio"
                                id={cat}
                                name="category"
                                value={cat}
                                checked={fileCategory === cat}
                                onChange={() => handleCategoryChange(cat)}
                            />
                            <label htmlFor={cat}>{cat}</label>
                        </div>
                    ))}
                </div>
            )}

            {isFileSelected && fileCategory && (
                <button onClick={handleUpload}>Upload</button>
            )}
            {uploadComplete && (
                <p style={{ color: 'green', marginTop: '10px' }}>
                    Upload successful! Please refresh the page to see the uploaded file.
                </p>
            )}
        </div>
    );
};

export default ExcelFileUploader;
