import React from 'react';
import { useSearchParams } from 'react-router-dom';

interface ListHeaderProps {
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar: React.FC<ListHeaderProps> = ({
  placeholder,
  value,
  onChange,
}) => {
  const [urlParams, setUrlParams] = useSearchParams();
  const nav = () => {
    if (value === '') {
      urlParams.delete('q');
    } else {
      urlParams.set('q', value);
    }
    setUrlParams(urlParams);
  }

  return (
    <div className="list-content-horizontal-header">
      <input
        type="search"
        placeholder={placeholder}
        value={value}
        className="search-bar"
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') { nav(); }
        }}
      />
      <button className="add-data-button" onClick={(e) => nav()}>
        <span className="icon">🔍</span> 검색
      </button>
    </div>
  );
};

export default SearchBar;
