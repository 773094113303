import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MixingFormulationWithMaterials, UploadedFile, WorkOrder } from '@twinsketch/topika-model';
import FormulationTable from "src/pages/workOrder/createNew/table/FormulationTable";
import FormulationModal from "src/pages/workOrder/createNew/FormulationModal";
import { useFetchData } from "src/hook/CustomHook";
import ApiService from "src/services/ApiService";
import {
    defaultRatiosByMaterialType, FormulationToMixingHelper, translateToFromulationToMixingHelper
} from 'src/pages/workOrder/createNew//table/utils/helper';
import TankTable from "src/pages/workOrder/createNew/table/TankTable";
import ROUTES from "src/constants/routes";
import 'src/styles/workOrder/work-order.css'
import { faPencilAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MinimumWorkOrderForm: React.FC<{}> = () => {

    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [formulations, setFormulations] = useState<MixingFormulationWithMaterials[]>([]);
    const [selectedFormulation, setSelectedFormulation] = useState<MixingFormulationWithMaterials | null>(null);
    const [formulationHelper, setFormulationHelper] = useState<FormulationToMixingHelper | null>({
        ratiosByMaterialType: defaultRatiosByMaterialType,
        activeMaterialKg: 800,
        formulation: null,
        elementsByMaterialType: null,
    });
    const [isFormulationSelectModalOpen, setIsFormulationSelectModalOpen] = useState(false);
    const [draftMessage, setDraftMessage] = useState<string | null>(null);
    const [hideSaveOrFormulationButton, setHideSaveOrFormulationButton] = useState<boolean>(false);
    const [saveMessage, setSaveMessage] = useState<string | null>(null);  // New state for save alert

    const [workOrder, setWorkOrder] = useState<
        Omit<WorkOrder, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>>({
            name: "",
            formulationId: "",
            formulationName: "",
            activeMaterialKg: 800,
            mixingTankVolumeFull: 700,
            mixingTankVolumeWorking: 490,
            coatingSpec: null,
            processYield: null,
            materialAmount: null,
            materialsNeeded: null,
            inspection: null,
            draft: false,
            flagged: false,
            files: [] // Assuming `files` is part of the WorkOrder model
        });

    const handleActiveMaterialKgChange = (value: number) => {
        setWorkOrder((prevWorkOrder) => ({
            ...prevWorkOrder,
            activeMaterialKg: value,
        }));

        if (formulationHelper) {
            setFormulationHelper({
                ...formulationHelper,
                activeMaterialKg: value,
            });
        }
    };
    const fetchWorkOrderData = async () => {
        if (!id) return;

        try {
            const fetchedData = await ApiService.fetchWorkOrderById(id);
            if (fetchedData?.formulationId) {
                const fetchedFormulation = await ApiService.fetchFormulationById(fetchedData.formulationId);
                const detailed = translateToFromulationToMixingHelper(fetchedFormulation, 800);

                setSelectedFormulation(fetchedFormulation);
                setFormulationHelper(detailed);
            }
            setWorkOrder(fetchedData || { ...workOrder });

            // Check for WORK_ORDER files and handle accordingly
            const hasWorkOrderFile = fetchedData.files?.some(
                (file: UploadedFile) => file.category === "WORK_ORDER"
            );

            if (hasWorkOrderFile) {
                await ApiService.updateWorkOrder(id, { ...fetchedData, draft: true });
                setHideSaveOrFormulationButton(true);
                setDraftMessage(null);
            } else {
                setHideSaveOrFormulationButton(false);
                setDraftMessage("This work order is draft. Please upload a valid work order excel file to make it ready.");
            }
        } catch (error) {
            console.error("Failed to fetch work order:", error);
            setError("Failed to fetch work order. Displaying default values.");
        }
    };

    useEffect(() => {
        fetchWorkOrderData();
    }, [id]);

    useEffect(() => {
        // Check if files are empty and set workOrder.draft to true if needed
        if (workOrder.files?.length === 0) {
            setWorkOrder((prevWorkOrder) => ({
                ...prevWorkOrder,
                draft: true,
            }));
            setDraftMessage("This work order is draft. Please upload work order excel file to make it ready.");
        } else {
            setDraftMessage(null);
        }
    }, [workOrder.files]);

    useFetchData(ApiService.fetchFormulations, setFormulations, setError);

    const handleSave = async () => {
        try {
            if (!workOrder) {
                throw new Error("Work order data is missing.");
            }
            const response = await ApiService.createWorkOrder(workOrder, workOrder.draft);
            const newWorkOrderId = response?.id;

            if (!newWorkOrderId) throw new Error("Draft ID not returned from API.");
            setSaveMessage("Item saved!");

            setTimeout(() => {
                setSaveMessage(null);
                // If you still need to navigate after saving, you can navigate here or after the timeout.
                navigate(ROUTES.WORK_ORDERS_EDIT(newWorkOrderId));
            }, 1500);

        } catch (err) {
            console.error("Failed to save draft:", err);
            setError("Failed to save draft. Please try again.");
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => { // for name and draft, flag checkboxes
        const { name, value, type, checked } = e.target;
        setWorkOrder((prevWorkOrder) => ({
            ...prevWorkOrder,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const [isEditingName, setIsEditingName] = useState<boolean>(false); // State to manage name editing mode

    const handleNameEditToggle = () => {
        setIsEditingName((prev) => !prev);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newName = e.target.value;
        setWorkOrder((prev) => ({
            ...prev,
            name: newName,
        }));

    };

    const renderNameInput = () => (
        <input
            type="text"
            id="workOrderName"
            name="name"
            value={workOrder.name}
            onChange={isEditingName ? handleNameChange : handleInputChange}
            placeholder="Enter Work Order Name"
            style={{ width: "400px", height: "30px" }}
        />
    );

    const renderEditingName = () => (
        <>
            {renderNameInput()}
            <button
                onClick={handleNameEditToggle}
                style={{
                    marginLeft: "0px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "#28a745"
                }}
                title="Save"
            >
                <FontAwesomeIcon icon={faSave} />
            </button>
        </>
    );

    const renderDisplayNameWithEdit = () => (
        <>
            <h1 className="form-title">{workOrder.name}</h1>
            <button
                onClick={handleNameEditToggle}
                style={{
                    marginLeft: "0px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "#28a745"
                }}
                title="Edit"
            >
                <FontAwesomeIcon icon={faPencilAlt} size="sm" />
            </button>
        </>
    );

    const renderDisplayNameOnly = () => (
        <h1 className="form-title">{workOrder.name}</h1>
    );

    return (
        <div>
            <div className="content-wrapper">
                <div>
                    {draftMessage && <div className="error-message" style={{ color: "red", textAlign: "left", marginBottom: "1rem" }}>
                        {draftMessage}
                    </div>}
                </div>
                <div>
                    <div>

                        <div className="form-group">
                            {id ? (
                                <div className="header-top-row">
                                    {isEditingName
                                        ? renderEditingName()
                                        : (!hideSaveOrFormulationButton
                                            ? renderDisplayNameWithEdit()
                                            : renderDisplayNameOnly()
                                        )
                                    }
                                </div>
                            ) : (
                                // If no id, display regular input field for new work orders
                                renderNameInput()
                            )}
                        </div>
                        <div className="card">
                            <h2>Formulation and Mixer Tank Volume</h2>
                            {!hideSaveOrFormulationButton && (
                                <div className="formulation-header">
                                    <button className="select-formulation-button" onClick={() => setIsFormulationSelectModalOpen(true)}>
                                        Select Formulation
                                    </button>
                                </div>
                            )}
                            <div className="form-group">
                                <label htmlFor="activeMaterialKg" style={{ display: 'block', marginBottom: '4px', fontWeight: 'bold' }}>
                                    Active Material (kg)
                                </label>
                                {hideSaveOrFormulationButton ? (
                                    // Display plain text with consistent styling
                                    <div
                                        id="activeMaterialKg"
                                        style={{
                                            width: '200px',
                                            height: '30px',
                                            padding: '4px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            backgroundColor: '#f9f9f9',
                                            boxSizing: 'border-box',
                                            lineHeight: '22px'
                                        }}
                                    >
                                        {workOrder.activeMaterialKg}
                                    </div>
                                ) : (
                                    // Render input with consistent styling
                                    <input
                                        type="number"
                                        id="activeMaterialKg"
                                        value={workOrder.activeMaterialKg}
                                        onChange={(e) => handleActiveMaterialKgChange(Number(e.target.value))}
                                        placeholder="Enter Active Material (kg)"
                                        style={{
                                            width: '200px',
                                            height: '30px',
                                            padding: '4px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            boxSizing: 'border-box'
                                        }}
                                    />
                                )}
                            </div>
                            <div className="tables-wrapper">
                                <FormulationTable
                                    formulation={formulationHelper}
                                    setFormulation={setFormulationHelper}
                                    onFormulationUpdate={(updatedFormulation) => {
                                        setFormulationHelper(updatedFormulation);
                                    }} />
                                <div className="table-wrapper">
                                    <TankTable
                                        mixingTankVolumeFull={workOrder.mixingTankVolumeFull || 700}
                                        mixingTankVolumeWorking={workOrder.mixingTankVolumeWorking || 480}
                                        onUpdate={(updatedValues) =>
                                            setWorkOrder((prevWorkOrder) => ({
                                                ...prevWorkOrder,
                                                ...updatedValues,
                                            }))
                                        } />
                                </div>
                            </div>
                        </div>
                        {!hideSaveOrFormulationButton && (
                            <div className="save-button-wrapper">
                                <button className="save-draft-button" onClick={handleSave}>
                                    Save
                                </button>
                                {saveMessage && (
                                    <span style={{ marginLeft: "10px", color: "green", fontSize: "0.9em" }}>
                                        {saveMessage}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {isFormulationSelectModalOpen && (
                    <FormulationModal
                        formulations={formulations}
                        onClose={() => setIsFormulationSelectModalOpen(false)}
                        onSelect={(formulation) => {
                            // Set the selected formulation in the state
                            setSelectedFormulation(formulation);

                            // Update the work order state with selected formulation details
                            setWorkOrder((prevWorkOrder) => ({
                                ...prevWorkOrder,
                                formulationId: formulation.id,
                                formulationName: formulation.name,
                            }));

                            // Translate and set the formulationHelper
                            const translatedFormulationHelper = translateToFromulationToMixingHelper(formulation, workOrder.activeMaterialKg);
                            setFormulationHelper(translatedFormulationHelper);

                            // Close the modal
                            setIsFormulationSelectModalOpen(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default MinimumWorkOrderForm;
