
import React from 'react';
import { AmountYield, Inspection, MaterialAmount, ProcessYield } from '@twinsketch/topika-model';

import 'src/styles/workOrder/work-order.css'
import 'src/styles/workOrder/mixing-table.css';

export const InspectionTableToComponent = ({ inspection }: { inspection: Inspection }) => {
    return (
        <table className="mixing-table" style={{ width: "500px" }} >
            <thead>
                <tr>
                    <th>Inspection</th>
                    <th>Solid Content</th>
                    <th>Viscosity</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td >Binder solution</td>
                    <td>{inspection.binderSolidContent}</td>
                    <td>{inspection.binderViscosityRange}</td>
                </tr>
                <tr>
                    <td>Slurry</td>
                    <td>{inspection.slurrySolidContent}</td>
                    <td>{inspection.slurryViscosityRange}</td>
                </tr>
            </tbody>
        </table>
    );
};

export const ProcessYieldTableToComponent = ({ processYield }: { processYield: ProcessYield }) => {
    return (
        <table className="mixing-table">
            <thead>
                <tr>
                    <th style={{ padding: '5px' }}>Process</th>
                    <th style={{ padding: '5px' }}>Yield (%)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Mixing</td>
                    <td>{processYield.mixing.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Coating</td>
                    <td>{processYield.coating.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Calendering</td>
                    <td>{processYield.calendering.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Slitting</td>
                    <td>{processYield.slitting.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td>{processYield.total.toFixed(2)}</td>
                </tr>
            </tbody>
        </table>
    );
};

export const AmountYieldsTableToComponent = ({ materialsNeeded }: { materialsNeeded: AmountYield[] }) => {
    const materialRows = materialsNeeded.map((material, index) => (
        <tr key={`${material.materialName}-${index}`}>
            <td>{material.materialName}</td>
            <td>{material.net.toFixed(2)}</td>
            <td>{material.min.toFixed(2)}</td>
        </tr>
    ));

    return (
        <table className="mixing-table">
            <thead>
                <tr>
                    <th>Material</th>
                    <th>Net (kg)</th>
                    <th>Min (kg)</th>
                </tr>
            </thead>
            <tbody>{materialRows}</tbody>
        </table>
    );
};

export const InstructionNotesToComponent = ({ noteText }: { noteText: string }) => {
    return (
        <div className="column column-2">
            <div style={{ fontSize: '0.9em' }}>
                {noteText
                    .split('\n')
                    .map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
            </div>
        </div>
    );
}


export const SpecTableToComponent = ({ specData }: { specData: (string | number | null)[][] }) => {
    // Function to check if a row contains all null values
    const isAllNullRow = (row: any[]): boolean => {
        return row.every(cell => cell === null);
    };

    // Function to check if a row is a title row based on the new condition
    const isTitleRow = (prevRow: any[], currentRow: any[]): boolean => {
        return (
            isAllNullRow(prevRow) &&
            typeof currentRow[0] === 'string' &&
            currentRow.slice(1).every(cell => cell === null)
        );
    };

    // Function to safely format cell content
    const formatCellValue = (cell: any): string => {
        if (cell === null || cell === undefined || cell === '') {
            return '-';
        }
        if (typeof cell === 'number') {
            return cell.toLocaleString(); // Format numbers with commas
        }
        if (typeof cell === 'boolean') {
            return cell ? 'True' : 'False';
        }
        return cell.toString();
    };

    const splitDataSections = (data: any[][]): any[][][] => {
        const sections: any[][][] = [];
        let currentSection: any[][] = [data[0]]; // Always consider the first row as a title

        for (let i = 1; i < data.length; i++) {
            if (i > 0 && isTitleRow(data[i - 1], data[i])) {
                sections.push(currentSection);
                currentSection = [];
            }
            if (isAllNullRow(data[i])) {
                continue;
            }
            currentSection.push(data[i]);
        }

        if (currentSection.length > 0) {
            sections.push(currentSection);
        }

        return sections;
    };

    const sections = splitDataSections(specData);

    return (
        <div>
            {sections.map((section, sectionIndex) => {
                if (section.length < 2) return null;  // Skip if section has no data

                // The very first row is always the main title of the section
                const title = section[0].filter(cell => cell !== null && cell != undefined).join(' | ') || 'Untitled Section';
                const contentRows = section.slice(1);

                return (
                    <div key={sectionIndex} className="table-section" >
                        <h4 style={{ fontWeight: 'bold' }}>
                            {title}
                        </h4>
                        <table className="mixing-table" style={{ width: '80%', }}>
                            <tbody>
                                {contentRows.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <td key={cellIndex} style={{ border: '1px solid #ddd' }}>
                                                {formatCellValue(cell)}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            })}
        </div>
    );
};

export const MixingTableToComponent = ({ mixingTableData }) => {
    // Function to safely format cell content
    const formatCellValue = (cell) => {
        if (cell === null || cell === undefined || cell === '') {
            return '-';
        }
        if (typeof cell === 'number') {
            return cell.toLocaleString(); // Format numbers with commas
        }
        return cell.toString();
    };

    // Separate binder and slurry sections
    const binderSection = mixingTableData.slice(2, 7);
    const slurrySection = mixingTableData.slice(7, 21);
    const mixingTotalSection = mixingTableData.slice(21);

    // console.log(binderSection)
    return (
        <div>
            <div className="table-section">
                <table className="mixing-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Process</th>
                            <th colSpan={2}>Materials</th>
                            <th>Weight (kg)</th>
                            <th>Density (g/㎤)</th>
                            <th>Volume (ℓ)</th>
                            <th>Lead time (min)</th>
                            <th>Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                        {binderSection.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ border: '1px solid #ddd' }}>
                                        {formatCellValue(cell)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {slurrySection.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ border: '1px solid #ddd' }}>
                                        {formatCellValue(cell)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {mixingTableData.slice(-1).map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ border: '1px solid #ddd' }}>
                                        {formatCellValue(cell)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
