import React, { useEffect, useState } from "react";
import Header from "src/components/common/Header";
import ErrorModal from "src/components/common/ErrorModal";
import Tabs from "src/pages/workOrder/form/CreateTab";
import "src/styles/workOrder/work-order.css";
import { UploadedFile, WorkOrder } from "@twinsketch/topika-model";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ApiService from "src/services/ApiService";
import { useFetchDataById } from "src/hook/CustomHook";
import FileService from "src/services/FileService";
import ExcelFileUploader from "./form/ExcelFileUploader";
import MinimumWorkOrderForm from "src/pages/workOrder/form/MinimumWorkOrderForm";
import {
    ProcessYieldTableToComponent,
    InspectionTableToComponent,
    AmountYieldsTableToComponent,
    InstructionNotesToComponent,
    SpecTableToComponent,
    MixingTableToComponent
} from "./form/DataToComponentHelper";
import { FilePreviewData, WorkOrderPreviewService } from "./form/WorkOrderPreviewService";

const FilePreviewModal: React.FC<{ data: FilePreviewData; onClose: () => void }> = ({ data, onClose }) => {
    return (
        <div>
            <div className="modal" style={{ display: 'block', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
                <div className="modal-content" style={{ backgroundColor: 'white', padding: '20px', margin: '50px auto', width: '80%', maxHeight: '80%', overflowY: 'auto' }}>
                    <div>
                        <h2>Mixing Table</h2>
                        <MixingTableToComponent mixingTableData={data.mixingTableData} />
                        <h2>Process Yield</h2>
                        <ProcessYieldTableToComponent processYield={data.processYieldData} />
                        <h2>Materials Amount</h2>
                        <AmountYieldsTableToComponent materialsNeeded={data.materialsNeeded} />
                        <h2>Insepction</h2>
                        <InspectionTableToComponent inspection={data.inspection} />
                        <h2>Instruction Notes</h2>
                        <InstructionNotesToComponent noteText={data.noteText} />
                        <h2>Spec Table</h2>
                        <SpecTableToComponent specData={data.coatingSpecData} />
                    </div>
                    <button onClick={onClose} style={{ marginTop: '10px', padding: '10px', backgroundColor: '#f44336', color: 'white', border: 'none' }}>
                        Close
                    </button>
                </div>
            </div>
        </div >
    );
};

const filePreviewService = new WorkOrderPreviewService();

const WorkOrderHigherForm: React.FC = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const { id } = useParams<{ id?: string }>();
    const [isWorkOrderCreated, setIsWorkOrderCreated] = useState<boolean>(false);
    const [workOrderData, setWorkOrderData] = useState<WorkOrder | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [fileData, setFileData] = useState<FilePreviewData | null>(null);

    const activeTab = searchParams.get('tab') || 'create';

    useEffect(() => {
        setIsWorkOrderCreated(!!id);
    }, [id]);

    useEffect(() => {
        if (id) {
            // Fetch files when the work order ID is available
            FileService.getFiles(id)
                .then((fetchedFiles) => setFiles(fetchedFiles))
                .catch((err) => setError(err.message));
        }
    }, [id]);

    useFetchDataById(ApiService.fetchWorkOrderById, id, setWorkOrderData, setError);

    const updateWorkOrderAfterUploadFile = async (workOrderFileUrl: string, workOrderData: WorkOrder): Promise<WorkOrder | void> => {
        try {
            const dataFromFile = await filePreviewService.getFilePreviewData(workOrderFileUrl);
            const updatedWorkOrder: WorkOrder = {
                ...workOrderData,
                processYield: dataFromFile.processYieldData,
                materialsNeeded: dataFromFile.materialsNeeded,
                inspection: dataFromFile.inspection,
                instructionNotes: dataFromFile.noteText,
                draft: false
            };
            return updatedWorkOrder;
        } catch (err) {
            console.error('Error updating work order:', err);
            alert('Failed to update the work order.');
        }
    };

    useEffect(() => {
        const updateWorkOrder = async () => {
            if (id && workOrderData && workOrderData.draft) {
                const workOrderFile = files.find((file) => file.category === "WORK_ORDER");
                if (workOrderFile) {
                    const decorated = await updateWorkOrderAfterUploadFile(workOrderFile.s3Url, workOrderData);
                    if (decorated) {
                        try {
                            await ApiService.updateWorkOrder(id, decorated);
                            // Optionally, refetch or update state if needed
                        } catch (error) {
                            console.error("Error updating work order in API:", error);
                            setError('Failed to update the work order with new file data.');
                        }
                    }
                }
            }
        };
        updateWorkOrder();
        // Only run this effect when dependencies change
    }, [id, workOrderData, files]);

    const handleFileUpload = async (uploadedFile: UploadedFile) => {
        if (workOrderData && id) {
            const updatedWorkOrder = {
                ...workOrderData,
                files: [...(workOrderData.files || []), uploadedFile]
            };
            setWorkOrderData(updatedWorkOrder);
            try {
                const updatedWorkOrderResponse = await ApiService.updateWorkOrder(id, updatedWorkOrder);
                console.log(updatedWorkOrderResponse);
                // navigate(ROUTES.WORK_ORDERS_EDIT(id));
            } catch (error) {
                setError('Failed to update work order. Please try again.');
            }
        }
    };

    const renderFileList = () => {
        if (files.length === 0) {
            return <p>No files uploaded for this work order.</p>;
        }

        return (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {files.map((file) => (
                    <li
                        key={file.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '20px',
                            padding: '10px',
                            borderBottom: '1px solid #ccc',
                        }}
                    >
                        {/* File Information */}
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ flex: 1 }}>
                                <a
                                    href={file.s3Url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '10px' }}
                                >
                                    {file.fileName}
                                </a>
                            </div>
                            <div>
                                {/* Preview Button */}
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleFilePreview(file.s3Url);
                                    }}
                                    style={{
                                        padding: '5px 10px',
                                        backgroundColor: '#2196F3',
                                        color: 'white',
                                        border: 'none',
                                        cursor: 'pointer',
                                        marginRight: '10px',
                                    }}
                                >
                                    Preview
                                </button>

                                {/* Download Button */}
                                <a
                                    href={file.s3Url}
                                    download
                                    style={{
                                        padding: '5px 10px',
                                        backgroundColor: '#4CAF50',
                                        color: 'white',
                                        border: 'none',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Download
                                </a>
                            </div>
                        </div>

                        {/* Notes */}
                        {file.notes && (
                            <p style={{ fontSize: '14px', color: '#555', margin: '5px 0' }}>
                                <strong>Notes:</strong> {file.notes}
                            </p>
                        )}

                        {file.category && (
                            <p style={{ fontSize: '14px', color: '#555', margin: '5px 0' }}>
                                <strong>category:</strong> {file.category}
                            </p>
                        )}

                        {/* Uploaded At Info */}
                        {file.uploadedAt && (
                            <p style={{ fontSize: '14px', color: '#555', margin: '5px 0' }}>
                                <strong>Uploaded at:</strong> {file.uploadedAt}
                            </p>
                        )}

                        {/* Edit and Delete Buttons */}
                        <div>
                            <button
                                onClick={() => handleEditFile(file.id)}
                                style={{
                                    marginRight: '10px',
                                    padding: '5px 10px',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDeleteFile(file.id)}
                                style={{
                                    padding: '5px 10px',
                                    backgroundColor: '#f44336',
                                    color: 'white',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Delete
                            </button>
                        </div>
                    </li>
                ))}
            </ul>

        );
    };

    const handleEditFile = async (fileId: string) => {
        try {
            if (!id || !workOrderData || !workOrderData.files) return;

            const fileIndex = workOrderData.files.findIndex((f) => f.id === fileId);
            if (fileIndex < 0) return;

            const currentNotes = workOrderData.files[fileIndex].notes || '';
            const newNotes = prompt('Enter new notes for this file:', currentNotes);
            if (newNotes === null) return;

            const updatedFile = { ...workOrderData.files[fileIndex], notes: newNotes };
            const updatedFiles = [...workOrderData.files];
            updatedFiles[fileIndex] = updatedFile;

            const updatedWorkOrder = { ...workOrderData, files: updatedFiles };

            await ApiService.updateWorkOrder(id, updatedWorkOrder);
            setWorkOrderData(updatedWorkOrder);
            console.log(`File with ID: ${fileId} updated notes to: ${newNotes}`);
        } catch (err) {
            console.error(err);
            setError('Failed to update file. Please try again.');
        }
    };

    const handleDeleteFile = async (fileId: string) => {
        try {
            if (!id || !workOrderData || !workOrderData.files) return;

            await FileService.deleteFile(fileId, id);

            const updatedFiles = workOrderData.files.filter((f) => f.id !== fileId);
            const updatedWorkOrder = { ...workOrderData, files: updatedFiles };

            await ApiService.updateWorkOrder(id, updatedWorkOrder);
            setWorkOrderData(updatedWorkOrder);
            console.log(`File with ID: ${fileId} has been deleted.`);
        } catch (err) {
            console.error(err);
            setError('Failed to delete file. Please try again.');
        }
    };

    const handleFilePreview = async (fileUrl: string) => {
        try {
            const data = await filePreviewService.getFilePreviewData(fileUrl);
            setFileData(data);
            setPreviewVisible(true); // Open the preview modal
        } catch (error: any) {
            console.error("Error previewing file:", error);
            setError(error.message || 'Failed to preview the file.');
        }
    };

    const tabs = [
        {
            key: 'create',
            label: id ? 'This Work Order' : 'Create Work Order',
            content: (
                <MinimumWorkOrderForm />
            ),
        },
        {
            key: 'uploadFiles',
            label: 'Upload Files',
            content: (
                <div>
                    {!isWorkOrderCreated && (
                        <p style={{ color: 'red' }}>
                            * Please save a Work Order first before uploading files.
                        </p>
                    )}
                    {isWorkOrderCreated && (
                        <ExcelFileUploader onFileUpload={handleFileUpload} workOrderId={id} />
                    )}
                </div>
            ),
        },
        {
            key: 'fileList',
            label: 'View Files',
            content: <div>{renderFileList()}</div>,
        },
    ];

    const handleTabChange = (key: string) => {
        setSearchParams({ tab: key });
    };

    return (
        <div>
            <Header />
            <ErrorModal message={error} onClose={() => setError(null)} />
            <div className="content-wrapper">
                <Tabs
                    tabs={tabs}
                    activeKey={activeTab}
                    onTabChange={handleTabChange}
                />
                {previewVisible && <FilePreviewModal data={fileData} onClose={() => setPreviewVisible(false)} />}
            </div>

        </div>
    );
};

export default WorkOrderHigherForm;
