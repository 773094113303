// Import necessary libraries
import { AmountYield, Inspection, ProcessYield } from '@twinsketch/topika-model';
import * as XLSX from 'xlsx';

// Define types
type DataCell = string | number | null;
type DataRow = DataCell[];

// Define the return type for better type safety
export interface FilePreviewData {
    processYieldData: ProcessYield;
    inspection: Inspection;
    noteText: string;
    materialsNeeded: AmountYield[];
    coatingSpecData: any[][];
    mixingTableData: any[][];
}

/**
 * Extracts specified columns from the data.
 *
 * @param data - The input data as an array of arrays.
 * @param cathodeKeywords - Possible headers for the first column (e.g., "Cathode", "Anode").
 * @param amountHeaders - Possible headers for the "Net amount" column.
 * @param quantityHeaders - Possible headers for the quantity column (e.g., "최소 필요량", "실투입량").
 * @returns The extracted data containing only the specified columns.
 */
function extractMaterialAmountColumns(
    data: DataRow[],
    cathodeKeywords: string[] = ["Cathode", "Anode"],
    amountHeaders: string[] = ["Net amount"],
    quantityHeaders: string[] = ["최소 필요량", "실투입량"]
): DataRow[] {
    if (!data || data.length === 0 || data[0].length === 0) {
        return [];
    }

    const header = data[0];
    const headerMap: { [key: string]: number } = {};

    header.forEach((h, idx) => {
        if (h !== null) {
            headerMap[h] = idx;
        }
    });

    // Helper function to find the first matching header
    const findHeaderIndex = (options: string[]): number | null => {
        for (const option of options) {
            if (headerMap.hasOwnProperty(option)) {
                return headerMap[option];
            }
        }
        return null;
    };

    const cathodeIdx = findHeaderIndex(cathodeKeywords);
    const amountIdx = findHeaderIndex(amountHeaders);
    const quantityIdx = findHeaderIndex(quantityHeaders);

    if (cathodeIdx === null || amountIdx === null || quantityIdx === null) {
        throw new Error("One or more required columns are missing in the data.");
    }

    const extractedData: DataRow[] = [];

    // Add new header
    extractedData.push([header[cathodeIdx], header[amountIdx], header[quantityIdx]]);

    // Process each data row
    for (let i = 1; i < data.length; i++) {
        const row = data[i];
        if (!row || row.length === 0) {
            continue; // Skip empty rows
        }

        const cathode = cathodeIdx < row.length ? row[cathodeIdx] : null;
        const amount = amountIdx < row.length ? row[amountIdx] : null;
        const quantity = quantityIdx < row.length ? row[quantityIdx] : null;

        extractedData.push([cathode, amount, quantity]);
    }

    return extractedData;
}

/**
 * Placeholder implementations for undefined functions.
 * You need to replace these with your actual implementations.
 */
function getSheetData(worksheet: XLSX.WorkSheet, range: XLSX.Range): DataRow[] {
    // Implement your logic to extract sheet data based on the range
    // For example, using XLSX.utils.sheet_to_json with range options
    const data: DataRow[] = XLSX.utils.sheet_to_json(worksheet, { header: 1, range });
    return data as DataRow[];
}

function extractSectionDataWithIndices(data: DataRow[], startIndex: number, endIndex: number): DataRow[] {
    return data.slice(startIndex, endIndex);
}

export const findSectionStartIndex = (data: any[][], keyword: string): number => {
    return data.findIndex(
        row => row.some(cell => typeof cell === 'string' && cell.includes(keyword))
    );
};

export function toInspection(data: any[][]): Inspection {
    const binderSolidContent = data[1].slice(3).join(' ').trim();
    const binderViscosityRange = data[2].slice(3).join(' ').trim();
    const slurrySolidContent = data[3].slice(3).join(' ').trim();
    const slurryViscosityRange = data[4].slice(3).join(' ').trim();
    return {
        binderSolidContent,
        binderViscosityRange,
        slurrySolidContent,
        slurryViscosityRange,
    };
}

export const findCoatingWidthIndex = (data: any[][]): number => {
    return findSectionStartIndex(data, "Coating width");
};

export const toExtractNotes = (data: any[][], nextSectionStartIndex): string => {
    return data.slice(0, nextSectionStartIndex - 1)
        .map(row => row.filter(cell => cell != null).join(' '))
        .join('\n');
};

export function convertToMaterialAmount(data: any[][]): AmountYield[] {
    console.log(data);

    let amountYields: AmountYield[] = [];
    data.forEach((row, index) => {
        if (index === 0) return; // skip the header row
        if (!row[0]) return; // skip empty or undefined rows

        amountYields.push({
            materialName: String(row?.[0] ?? "N/A"),
            net: row?.[1] !== undefined && !isNaN(parseFloat(row[1])) ? parseFloat(row[1]) : 0,
            min: row?.[2] !== undefined && !isNaN(parseFloat(row[2])) ? parseFloat(row[2]) : 0,
        });
    });
    return amountYields;
}

export function convertToProcessYield(data: any[][]): ProcessYield {
    // The rows containing Mix, Coat, Cal, Slit, Total yields
    // are assumed to follow the '% Yield' row in the exact order below.
    const mixRow = data[1]; // e.g., ["NMP", ..., "Mix", 0.95]
    const coatRow = data[2]; // e.g., ["코스모신소재 NCM523", ..., "Coat", 0.8]
    const calRow = data[3]; // e.g., ["도전재(불용자재 사용)", ..., "Cal", 0.95]
    const slitRow = data[4]; // e.g., ["Solvay 5130...", ..., "Slit", 0.98]
    const totalRow = data[5]; // e.g., [null, 0, "kg", 0, "kg", "Total", 0.70756]

    // Parse numeric yield values from the last column of each row
    const mixing = (parseFloat(mixRow?.[1]) || 0) * 100;
    const coating = (parseFloat(coatRow?.[1]) || 0) * 100;
    const calendering = (parseFloat(calRow?.[1]) || 0) * 100;
    const slitting = (parseFloat(slitRow?.[1]) || 0) * 100;
    const total = (parseFloat(totalRow?.[1]) || 0) * 100;

    return {
        mixing,
        coating,
        calendering,
        slitting,
        total,
        calculateTotalYield() {
            // Convert each percentage back to a decimal for the multiplication
            // then multiply the final product by 100 to get it as a percentage.
            //
            // e.g., if mixing = 95, coating = 80, calendering = 95, slitting = 98,
            // the calculation is:
            //   (95/100) * (80/100) * (95/100) * (98/100) * 100 ≈ 70.756
            const mixDec = this.mixing / 100;
            const coatDec = this.coating / 100;
            const calDec = this.calendering / 100;
            const slitDec = this.slitting / 100;
            return mixDec * coatDec * calDec * slitDec * 100;
        },
    };
}

function setError(message: string): void {
    // Implement your error handling logic
    // For example, throw an error or log it
    throw new Error(message);
}

export interface WorkOrderPreviewServiceInterface {
    getFilePreviewData(fileUrl: string): Promise<FilePreviewData>;
}

/**
 * FilePreviewService Class
 */
export class WorkOrderPreviewService implements WorkOrderPreviewServiceInterface {

    /**
     * Generates HTML preview of the Excel file.
     *
     * @param fileUrl - The URL of the Excel file to preview.
     * @returns A promise that resolves to the HTML string representing the preview.
     */

    async getFilePreviewData(fileUrl: string): Promise<FilePreviewData> {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch the file. Status: ${response.status}`);
            }
            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: "array" });

            // Identify the target sheet
            const targetSheetNames = ['Anode', 'anode', 'cathode', 'Cathode'];
            const matchedSheetName = workbook.SheetNames.find((name) =>
                targetSheetNames.includes(name.trim())
            );

            if (!matchedSheetName) {
                throw new Error("No matching sheet found in the Excel file.");
            }

            const worksheet = workbook.Sheets[matchedSheetName];
            const firstColumnSheet = getSheetData(worksheet, { s: { r: 0, c: 1 }, e: { r: 40, c: 9 } });

            const mixingTableStartIndex = findSectionStartIndex(firstColumnSheet, "Process");
            const inspectionTableStartIndex = findSectionStartIndex(firstColumnSheet, "Inspection");

            const mixingTableData = this.trimEmptyArrays(
                extractSectionDataWithIndices(firstColumnSheet, mixingTableStartIndex, inspectionTableStartIndex)
            );
            const inspectionTableData = this.trimEmptyArrays(
                extractSectionDataWithIndices(firstColumnSheet, inspectionTableStartIndex, inspectionTableStartIndex + 6)
            );
            const paddedMixingTableData = mixingTableData.map(row => {
                const paddedRow = [...row];
                while (paddedRow.length < 9) {
                    paddedRow.push(null);
                }
                return paddedRow;
            });

            const inspection = toInspection(inspectionTableData);

            const secondColumnSheet = getSheetData(worksheet, { s: { r: 0, c: 11 }, e: { r: 50, c: 17 } });
            const coatingSpecTableStartIndex = findSectionStartIndex(secondColumnSheet, 'Coating width') - 1;
            const materialAmountTableStartIndex = findSectionStartIndex(secondColumnSheet, 'Net amount');
            const processTableStartIndex = findSectionStartIndex(secondColumnSheet, 'Process')

            const noteText = toExtractNotes(secondColumnSheet, coatingSpecTableStartIndex);

            const coatingSpecTableEndIndex = (processTableStartIndex > materialAmountTableStartIndex)
                ? materialAmountTableStartIndex
                : processTableStartIndex;

            const coatingSpecData = this.trimEmptyArrays(extractSectionDataWithIndices(secondColumnSheet,
                coatingSpecTableStartIndex, coatingSpecTableEndIndex));

            // Function to determine the max row length without trailing empty cells
            const getMaxValidRowLength = (data: any[][]) => {
                return data.reduce((maxLength, row) => {
                    // Remove trailing null or empty values
                    const trimmedRow = [...row].reverse().filter(cell => cell !== null && cell !== '').reverse();
                    return Math.max(maxLength, trimmedRow.length);
                }, 0);
            };
            const maxRowLength = getMaxValidRowLength(coatingSpecData);

            const paddedSpecTableData: any[][] = coatingSpecData.map(row => {
                const paddedRow: any[] = [...row];
                while (paddedRow.length < maxRowLength) {
                    paddedRow.push(null);
                }
                return paddedRow;
            });

            // Slice the relevant section from data
            const slicedDataForProcessYield = extractSectionDataWithIndices(secondColumnSheet, processTableStartIndex, processTableStartIndex + 6);

            // Determine processing type based on the first element
            const isVertical = secondColumnSheet[processTableStartIndex][0] === 'Process';

            // Handle processing based on type
            const processYieldData = isVertical
                ? this.handleVerticalProcessYield(slicedDataForProcessYield)
                : this.handleHorizontalProcessYield(slicedDataForProcessYield);

            const slicedDataForMaterial = this.trimEmptyArrays(
                extractSectionDataWithIndices(secondColumnSheet, materialAmountTableStartIndex, materialAmountTableStartIndex + 8));
            const materialsNeeded = convertToMaterialAmount(extractMaterialAmountColumns(slicedDataForMaterial));

            return {
                noteText,
                inspection,
                processYieldData,
                materialsNeeded,
                coatingSpecData: paddedSpecTableData,
                mixingTableData: paddedMixingTableData
            };
        } catch (error) {
            console.error("Error generating file preview:", error);
            throw error;
        }
    }

    /**
     * Trims leading and trailing empty arrays from the input array.
     *
     * @param arr - The array to be trimmed.
     * @returns A new array without leading and trailing empty arrays.
     */
    private trimEmptyArrays<T>(arr: T[][]): T[][] {
        const trimmedArray = [...arr];

        // Trim leading empty arrays
        let start = 0;
        while (
            start < trimmedArray.length &&
            Array.isArray(trimmedArray[start]) &&
            trimmedArray[start].length === 0
        ) {
            start++;
        }

        // Trim trailing empty arrays
        let end = trimmedArray.length;
        while (
            end > start &&
            Array.isArray(trimmedArray[end - 1]) &&
            trimmedArray[end - 1].length === 0
        ) {
            end--;
        }

        return trimmedArray.slice(start, end);
    }

    /**
     * Handles vertical processing of sliced data.
     *
     * @param slicedData - The sliced data to process vertically.
     * @returns Processed yield data.
     */
    private handleVerticalProcessYield(slicedData: DataRow[]): any {
        console.log("Vertical processing");
        return convertToProcessYield(slicedData);
    }

    /**
     * Handles horizontal processing of sliced data.
     *
     * @param slicedData - The sliced data to process horizontally.
     * @returns Processed yield data or null if processing fails.
     */
    private handleHorizontalProcessYield(slicedData: DataRow[]): any | null {
        console.log("Horizontal processing");
        const processColumnIndex = this.findProcessColumnIndex(slicedData[0], 'Process')
        if (processColumnIndex === -1) {
            setError('No "Process" column found in the header.');
            return null;
        }
        const processedData = this.sliceDataFromProcess(slicedData, processColumnIndex);
        return convertToProcessYield(processedData);
    }

    /**
     * Finds the index of the "Process" column in the header row.
     *
     * @param headerRow - The header row.
     * @param processKeyword - The keyword to search for.
     * @returns The index of the "Process" column or -1 if not found.
     */
    private findProcessColumnIndex(headerRow: DataRow, processKeyword: string = 'Process'): number {
        return headerRow.findIndex(cell =>
            typeof cell === 'string' && cell.toLowerCase() === processKeyword.toLowerCase()
        );
    }

    /**
     * Slices each row from the "Process" column onwards.
     *
     * @param data - The data to slice.
     * @param processIndex - The index of the "Process" column.
     * @returns The sliced data.
     */
    private sliceDataFromProcess(data: DataRow[], processIndex: number): DataRow[] {
        return data.map(row => {
            // Ensure the row has enough columns
            if (row.length > processIndex) {
                return row.slice(processIndex);
            }
            // If not, return an empty array or handle as needed
            return [];
        });
    }
}
