import React, { useState, useMemo } from 'react';
import { useAppData } from 'src/components/AppDataContext';
import Header from 'src/components/common/Header';
import PlatformUpdateModal, { PlatformUpdate, platformUpdatesData } from 'src/components/mainPage/PlatformUpdateModal';
import ROUTES, { getDetailRoute } from 'src/constants/routes';
import 'src/styles/MainPage.css';

const MainPage: React.FC = () => {
  const { materials, formulations } = useAppData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState<PlatformUpdate | null>(null);

  // Combine all data, setting recentDate to the latest of createdAt/updatedAt and flagging if updated
  const allData = useMemo(() => [

    ...materials.map(item => ({
      ...item,
      dataType: 'MATERIALS',
      recentDate: new Date(item.updatedAt || item.createdAt),
      isUpdated: !!item.updatedAt
    })),
    ...formulations.map(item => ({
      ...item,
      dataType: 'FORMULATIONS',
      recentDate: new Date(item.updatedAt || item.createdAt),
      isUpdated: !!item.updatedAt
    })),

  ].sort((a, b) => b.recentDate.getTime() - a.recentDate.getTime()), [materials, formulations]);

  const getTitle = (dataType: 'MATERIALS' | 'FORMULATIONS', item: any): string => {
    switch (dataType) {

      case 'MATERIALS':
        return item.modelName || 'No Model Name';
      case 'FORMULATIONS':
        return item.name || 'No Name';
      default:
        return 'Untitled';
    }
  };

  const getKorean = (dataType: 'MATERIALS' | 'FORMULATIONS', item: any): string => {
    switch (dataType) {

      case 'MATERIALS':
        return '물질';
      case 'FORMULATIONS':
        return '전극조성';
      default:
        return '-';
    }
  };

  const openModal = (content: PlatformUpdate) => {
    setSelectedContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedContent(null);
  };

  return (
    <div>
      <Header />
      <div className="main-page">
        <aside className="sidebar">
          <section className="platform-updates">
            <h2>🔔 Platform Updates</h2>
            <ul className="update-list">
              {platformUpdatesData.map(update => (
                <li key={update.id} className="update-item" onClick={() => openModal(update)}>
                  <span className="update-title">{update.title}</span>
                  <span className="update-date">{new Date(update.date).toLocaleDateString()}</span>
                </li>
              ))}
            </ul>
          </section>

          <section className="resources">
            <h2>📂 Resources</h2>
            <ul className="resource-list">
              <li><a href="https://docs.google.com/document/d/18NhSa2dsl_eihFucYS60kuRzXyAllnb3f1VNtWAkzGA" target="_blank" rel="noopener noreferrer">User Guide</a></li>
              <li><a href="https://forms.gle/UH4z4fQQEFogBRU58" target="_blank" rel="noopener noreferrer">Submit Feedback</a></li>
              <li><a href="https://docs.google.com/spreadsheets/d/1KzuOqvoDebmPCGN3oQIiMZWQos-ok0nYHFNor8C6DZU" target="_blank" rel="noopener noreferrer">Feedback Status</a></li>
            </ul>
          </section>
        </aside>

        <main className="main-content">
          <div className="main-data-grid">
            {allData.length > 0 ? (
              allData.map((item) => (
                <div className="main-data-card" key={item.id}>
                  <div className="tag-container">
                    <span className={`main-status-tag ${item.isUpdated ? 'updated' : 'new'}`}>
                      {item.isUpdated ? 'Updated' : 'New'}
                    </span>
                    <span className="main-data-type-tag">{getKorean(item.dataType as 'MATERIALS' | 'FORMULATIONS', item)}</span>
                  </div>
                  <a href={getDetailRoute(item.dataType as 'MATERIALS' | 'FORMULATIONS', item.id)}
                    className="main-data-title">
                    {getTitle(item.dataType as 'MATERIALS' | 'FORMULATIONS', item)}
                  </a>
                  <p className="main-data-metadata">
                    by {item.updatedBy || item.createdBy} on {item.recentDate.toLocaleDateString()}
                  </p>
                </div>
              ))
            ) : (
              <p>No recently added data available.</p>
            )}
          </div>
        </main>

        <PlatformUpdateModal isOpen={isModalOpen} onClose={closeModal} content={selectedContent} />
      </div>
    </div>
  );
};

export default MainPage;
